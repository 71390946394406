import { IUser } from 'app/shared/model/user.model';

export interface IUserProfile {
  id?: number;
  occupation?: string | null;
  gender?: string | null;
  isMentor?: boolean | null;
  accessToken?: string | null;
  year?: number;
  helpCommunityDescription?: string | null;
  termsAccepted?: boolean | null;
  phoneNumber?: string | null;
  selfDescription20?: string | null;
  selfDescription30?: string | null;
  selfDescription40?: string | null;
  selfDescription50?: string | null;
  selfDescription60?: string | null;
  selfDescription70?: string | null;
  selfDescription80?: string | null;
  selfDescription90?: string | null;
  selfDescription100?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  instagramUrl?: string | null;
  imageName?: string | null;
  lat?: string | null;
  lng?: string | null;
  user?: IUser | null;
  mentees_count?: number;
  mentors_count?: number;
}

export const defaultValue: Readonly<IUserProfile> = {
  isMentor: false,
  termsAccepted: false,
};
